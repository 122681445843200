<template>
    <div :style="getElementStyle">
  
      <!-- Below code is for Before Field View -->
    <div v-if="!isDefalutPos">
      <div style="display: flex; align-items: center;">
        <label for="text" style="margin-right: 5px; white-space: nowrap;">{{ data.label }} </label>
          <span
            v-if="data.validations.required"
            style="color: red; font-weight: bold; margin-right:5px"
          >
            *
          </span>
          <span> <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i></span>
          <span v-if="data.description" style="margin-right: 5px;">
            <i
              class="el-icon-info"
              :title="data.description"
            ></i>
          </span>
          
          <input
            :style="getStyle"
            :placeholder="data.placeholder"
            v-model="selectedValue"
            :suffix-icon="data.properties.el_icon" class="slv"
          >
      </div>
      <div>
            <span   v-if="isActive
          ||
          isDataTableField" class="setting-icon">
            <i
            class="el-icon-s-tools"
            @click="openSettings"
          ></i>
          </span>
        </div>
    </div>
  
            <!-- Below Code is for Top Field View -->
            
      <el-row align="middle" v-else>
        <el-col v-if="!isInDataTable" :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
          <label for="text">{{ data.label }} </label>
          <span
            v-if="data.validations.required"
            style="color: red; font-weight: bold"
          >
            *
          </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        </el-col>
  
        <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
          <span v-if="data.description">
            <i
              class="el-icon-info"
              :title="data.description"
            ></i>
          </span>
        </el-col> -->
        <el-row
          v-if="isActive
          ||
          isDataTableField"
          class="setting-icon"
        >
          <i
            class="el-icon-s-tools"
            @click="openSettings"
          ></i>
        </el-row>
        <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
          <input
          :style="{'text-align': inputTextAlignment(), ...getStyle}"
            :placeholder="data.placeholder"
            v-model="selectedValue"
            :suffix-icon="data.properties.el_icon" class="slv"
          >
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "ConcatenateView",
    components: {},
    props: ["data", "value", "isActive", "isDataTableField","isInDataTable"],
  
    computed: {
      ...mapGetters("globalVariables", ["getGlobalVariableById"]),
  
      selectedValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
      computedStyles() {
        const styles = {};
  
        // Set display to flex
        styles.display = 'flex';
  
        // Set justify-content based on data.styles.labelAlignments
        if (this.data?.styles?.labelAlignments) {
          styles.justifyContent = this.data.styles.labelAlignments;
        }
  
        // Add other dynamic styles if needed
  
        return styles;
      },
      getStyle() {
        let inputStyle = "";
        if (this.data.styles) {
          inputStyle += this.data.styles.input_background
            ? `background-color: ${this.data.styles.input_background}`
            : "";
        }
  
        inputStyle += ";";
        return inputStyle;
      },
      isDefalutPos() {
        if (this.data.styles && this.data.styles.labelStyle == "right") {
          return false;
        } else {
          return true;
        }
  
      },
      getElementStyle() {
        let borderStyle = "";
  
        if (this.data.styles) {
          let type = this.data.styles.border_type
            ? this.data.styles.border_type
            : "solid";
          let size = this.data.styles.border_size
            ? this.data.styles.border_size + "px"
            : "0px";
          let color = this.data.styles.border_color
            ? this.data.styles.border_color
            : "";
          let font_size =
            this.data.styles &&
            this.data.styles.font &&
            this.data.styles.font.font_size
              ? this.data.styles.font.font_size
              : "";
          let bold =
            this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
              ? "bold"
              : "";
          let italic =
            this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
              ? "italic"
              : "";
  
          borderStyle = "border:" + type + " " + " " + size + " " + color;
  
          borderStyle += ";";
          borderStyle += this.data.styles.background
            ? `background: ${this.data.styles.background}`
            : "";
          borderStyle += ";";
          borderStyle += this.data.styles.label_color
            ? `color: ${this.data.styles.label_color}`
            : "";
          borderStyle += ";";
          borderStyle += "font-size:" + font_size + "px;";
          if (bold) {
            borderStyle += "font-weight:" + bold + ";";
          }
          if (italic) {
            borderStyle += "font-style:" + italic + ";";
          }
          borderStyle += this.data.styles.label_color
            ? `color: ${this.data.styles.label_color}`
            : "";
        }
  
        return borderStyle;
      }
    },
    data() {
      return {
        validations: [],
        options: [],
        isList: false,
        showLabel: true,
        inputTextAlignmentValue: 'left',
      };
    },
    mounted() {
      if (this.data.styles) {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
        }
      }
  
      this.options = this.data.options || [];
      if (this.data.is_global_variable && this.data.global_variable_id) {
        this.setGlobalVariableData();
      }
    },
    methods: {
      copyDocument() {
        this.$emit("copy-document", this.data.key);
      },
      inputTextAlignment() {
      return this.data.styles && this.data.styles.inputTextAlignments
        ? this.data.styles.inputTextAlignments
        : 'left';
      },
      openSettings() {
        this.$emit("settings");
      }
    },
    watch: {
      'data.styles.inputTextAlignments': {
      handler(newVal) {

        this.inputTextAlignmentValue = newVal;
      },
      immediate: true 
      }
    }
  };
  </script>
  
  <style lang="scss" >
  </style>
  
  <style lang="scss" scoped>
  .slv{
        -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 32px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      &:hover {
      border-color: #409EFF;
      }
      &:focus {
      border-color: #409EFF; 
      }
      &::placeholder {
      color: #ccc; 
    }
  }
  </style>