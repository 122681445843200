<template>
  <div
    :style="getElementStyle"
    class="eb-time"
  >
  <div v-if="!isDefalutPos" style="display: flex; align-items: center;">
      <div style="white-space: nowrap;">
        <label for="text">{{ data.label }}</label>
        <!-- </el-col>
        <el-col :span="showLabel && isDefalutPos ? 4 : 0"> -->
        <el-popover
          style="padding-left: 5px"
          v-if="data.description"
          title="Help"
          placement="too-start"
          trigger="click"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </el-popover>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px;"
        >
          *
        </span>
        <span> <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i></span>
      </div>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        8
        :
        24" style="display: contents;">
        <el-time-picker
          :style="getStyle"
          is-range
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start time'"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          :end-placeholder="data.placeholder1 || 'End time'"
        >
        </el-time-picker>
      </el-col>
  </div>
    <el-row align="middle" v-else>
      <el-col v-if="!isInDataTable" :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24" :style="computedStyles">
        <label for="text">{{ data.label }}</label>
        <!-- </el-col>
        <el-col :span="showLabel && isDefalutPos ? 4 : 0"> -->
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
       <el-popover
          style="padding-left: 10px"
          v-if="data.description"
          title="Help"
          placement="too-start"
          trigger="click"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </el-popover>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
      </el-col>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <el-time-picker
          :style="getStyle"
          is-range
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start time'"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          :end-placeholder="data.placeholder1 || 'End time'"
        >
        </el-time-picker>
      </el-col>
    </el-row>
    <!--<div>
        {{data.label}}
        <span v-if="data.description">
          <i class="el-icon-info" :title="data.description" />
        </span>
        <span v-if="isActive" class="setting-icon" @click="openSettings">
          <i class="el-icon-s-tools" />
        </span>
      </div>
        <template v-if="data.input_type=='DATE'">
          <el-date-picker
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            type="date"
            :placeholder="data.placeholder"
          ></el-date-picker>
        </template>
  
        <template v-if="data.input_type=='TIME'">
          <el-time-picker
            arrow-control
            :style="getStyle"
            prefix-icon="el-icon-time"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            type="date"
            :placeholder="data.placeholder"
          ></el-time-picker>
        </template>
        <template v-if="data.input_type=='DATE_TIME'">
          <el-date-picker
            type="datetime"
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            :placeholder="data.placeholder"
          ></el-date-picker>
        </template>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {},
  props: ["data", "value", "isActive", "isDataTableField","isInDataTable"],

  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px
      ;
      width: ${this.data.width}px !important;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getTimeProperties() {
      if (
        this.data?.properties?.time_range &&
        this.data?.properties?.step_time
      ) {
        let properties = {};
        properties["start"] = moment(
          this.data?.properties?.time_range[0]
        ).format("HH:mm");
        properties["end"] = moment(this.data?.properties?.time_range[1]).format(
          "HH:mm"
        );
        if (
          this.data?.properties?.step_time == 60 ||
          this.data?.properties?.step_time == "60"
        ) {
          properties["step"] = "01:00";
        } else {
          properties["step"] = "00:" + this.data?.properties?.step_time;
        }
        return properties;
      }
      return {
        start: "00:00",
        step: "00:30",
        end: "23:59"
      };
    }
  },
  data() {
    return {
      validations: [],
      // isDefalutPosq: true,
      showLabel: true
    };
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "TIME_RANGE") {
          this.isList = true;
          this.data.selectedValue = this.getGlobalVariableById.value;
          this.data.is_list = true;
        } else {
          this.data.selectedValue = this.getGlobalVariableById.value;
          this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss" scopped>
.el-date-editor.el-input {
  width: 100% !important;
}
.eb-time {
  height: 74px !important;
}
</style>
