<template>
  <div :style="getElementStyle">

    <div>
      <!-- <span style="display: none"  >{{ isDefalutPosq}}</span> -->
      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPosq ? 8 : data.description ? 20 : 24">
          {{data.label}}<span
            v-if="data.validations.required"
            style="color:red;font-weight:bold;"
          >
            *
          </span>
          <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
        </el-col>
        <el-col :span="(showLabel && isDefalutPosq)?4:0">
          <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        </el-col>
             </el-row>
      <span
        v-if="isActive"
        class="setting-icon"
        @click="openSettings"
      >
        <i class="el-icon-s-tools"/>
      </span>
    </div>
    <div
      class="icon-block"
      :style="getStyle"
    >
      <div
        class="icon"
        v-if="!isList"
      >

        <span v-if="this.data.image_url">
          <img
            :height="this.data.height - 30"
            :width="this.data.width"
            :src="this.data.image_url"
            alt="icon"
          />
        </span>
        <span v-else>
          <img
            alt="Avatar"
            src="../../../../src/assets/img/default_img.jpg"
            :height="this.data.height - 30"
            :width="this.data.width"
            class="inline-block"
          />
          <!-- <img
          :height="this.data.height - 30"
          :width="this.data.width" 
          :src="this.data.image_url"
          alt="icon" 
        /> -->
          <!-- <el-button type="primary" >Upload</el-button> -->
        </span>

      </div>
      <div
        class="icon"
        v-if="isList"
      >
        <img
          :height="this.data.height - 30"
          :width="this.data.width"
          :src="getLogoUrlGlobal"
          alt="icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ImageView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField"],
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    getStyle() {
      return `height:${this.data.height - 30}px`;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if(this.data.styles.labelStyle == 'right'){
      //   this.isDefalutPos = false;
      // }
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "IMAGE") {
          this.getLogoUrlGlobal = this.getGlobalVariableById.image_url;
          this.isList = true;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {
  
      }
    }
  },
  data() {
    return {
      validations: [],
      isDefalutPos: true,
      showLabel: true,
      isList: false
    };
  }
};
</script>

<style lang="scss">
.info-right {
  margin-left: 55%;
}
</style>