var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eb-time",style:(_vm.getElementStyle)},[(!_vm.isDefalutPos)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.description)?_c('el-popover',{staticStyle:{"padding-left":"5px"},attrs:{"title":"Help","placement":"too-start","trigger":"click","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})]):_vm._e(),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e()])],1),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{staticStyle:{"display":"contents"},attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      8
      :
      24}},[_c('el-time-picker',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"is-range":"","range-separator":"To","start-placeholder":_vm.data.placeholder || 'Start time',"prefix-icon":"el-icon-time","end-placeholder":_vm.data.placeholder1 || 'End time'}})],1)],1):_c('el-row',{attrs:{"align":"middle"}},[(!_vm.isInDataTable)?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1),(_vm.data.description)?_c('el-popover',{staticStyle:{"padding-left":"10px"},attrs:{"title":"Help","placement":"too-start","trigger":"click","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})]):_vm._e(),(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e()],1):_vm._e(),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[_c('el-time-picker',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"is-range":"","range-separator":"To","start-placeholder":_vm.data.placeholder || 'Start time',"prefix-icon":"el-icon-time","end-placeholder":_vm.data.placeholder1 || 'End time'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }